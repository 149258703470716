.staffing-top-div {
  padding: vw(80px) vw(173px) 0 vw(173px);
}
.staffing-img-div {
  //width: vw(958px);
  width: vw(697px);
}
.staffing-card-div {
  padding: vw(130px) vw(59px) 0 vw(87px);
}
.staffing-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  //width: vw(480px);
  width: vw(338px);
  height: auto;
  margin-left: vw(15px);
  //margin-right: vw(15px);
  padding: vw(10px);
  margin-bottom: vw(140px);
}
.staffing-card-img-div {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.staffing-card-img {
  width: vw(127px);
  margin-bottom: vw(40px);
}
.staffing-card-text-div {
  width: 100%;
  height: vw(50px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: vw(23px);
}
.staffing-card-text {
  //font-size: vw(29px);
  //line-height: vw(35px);
  font-size: vw(24px);
  line-height: vw(25px);
  color: #091133;
  @include fontDmsBold;
}
.staffing-card-desc {
  //font-size: vw(21px);
  //line-height: vw(30px);
  font-size: vw(16px);
  line-height: vw(25px);
  color: #5d6970;
  text-align: center;
  @include fontMulishRegular;
}
.staffing-top-div {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: vw(167px);
    left: -1%;
    height: vw(167px);
    top: 1%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/img/background/bg2.svg");
  }
}
