/*==== Poppins Semibold  ===*/
@font-face {
  font-family: Poppins-SemiBold;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

/*=== Poppins Regular ===*/
@font-face {
  font-family: Poppins-Regular;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

/*=== Poppins Bold ===*/
@font-face {
  font-family: Poppins-Bold;
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}

/*=== Poppins Medium ===*/
@font-face {
  font-family: Poppins-Medium;
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

/*=== Poppins Extrabold ===*/
@font-face {
  font-family: Poppins-ExtraBold;
  src: url("../fonts/Poppins/Poppins-ExtraBold.ttf");
}

/*=== Poppins Black ===*/
@font-face {
  font-family: Poppins-Black;
  src: url("../fonts/Poppins/Poppins-Black.ttf");
}
/*------------------------------------------------------

                  DM Sans

-----------------------------------------------------*/
@font-face {
  font-family: DMSans-Bold;
  src: url("../fonts/DM_Sans/DMSans-Bold.ttf");
}

@font-face {
  font-family: DMSans-Regular;
  src: url("../fonts/DM_Sans/DMSans-Regular.ttf");
}
@font-face {
  font-family: DMSans-Medium;
  src: url("../fonts/DM_Sans/DMSans-Medium.ttf");
}
/*------------------------------------------------------

                 Mulish

-----------------------------------------------------*/
@font-face {
  font-family: Mulish-Regular;
  src: url("../fonts/Mulish/static/Mulish-Regular.ttf");
}
@font-face {
  font-family: Mulish-SemiBold;
  src: url("../fonts/Mulish/static/Mulish-SemiBold.ttf");
}
@font-face {
  font-family: Mulish-Bold;
  src: url("../fonts/Mulish/static/Mulish-Bold.ttf");
}
@font-face {
  font-family: Mulish-ExtraBold;
  src: url("../fonts/Mulish/static/Mulish-ExtraBold.ttf");
}
