.our-services-main-div {
  padding: vw(158px) vw(123px) vw(680px) vw(122px);
}
.our-service-main-path-img {
  width: vw(44px);
  height: auto;
  margin-bottom: vw(26px);
}
.our-service-main-font-20-mulish {
  //font-size: vw(25px);
  //line-height: vw(30px);
  font-size: vw(20px);
  line-height: vw(30px);
  @include fontMulishRegular;
  color: #696871;
  padding-top: vw(23px);
}
.pt-74 {
  padding-top: vw(74px);
}
.home-our-service-div--color-white {
  .home-our-service-div {
    background: white;
    padding-top: 0;
  }
  .home-our-service-div::after {
    display: none;
  }
}
.our-services-fold-two-title {
  font-size: vw(50px);
  line-height: vw(66px);
  letter-spacing: 1px;
  color: #091133;
  @include fontMedium;
  padding-top: vw(100px);
}
.our-services-fold-two-subtitle {
  font-size: vw(36px);
  line-height: vw(57px);
  letter-spacing: -1.56316px;
  color: #5454d4;
  @include fontDmsMedium;
}
.our-service-card--card1 {
  width: vw(1195px) !important;
  min-height: vw(230px);
  margin-top: vw(81px);
  padding: vw(55px) vw(54px) vw(44px) vw(52px);
  .learn-more-text {
    width: fit-content;
  }
}
