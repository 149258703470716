/* ===============================================================
                    
                    Global reset and css

================================================================== */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow-x: hidden !important;
  min-height: 100vh !important;
  background: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0 !important;
}

a,
a:hover {
  text-decoration: inherit !important;
  color: inherit !important;
}

//   button   //
button {
  border: none;
  outline: none;
}

button:focus,
input:focus,
textarea:focus {
  outline: 0 !important;
}
.outline-0 {
  outline: 0;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0;
}

//*::placeholder {
//  color: #848484;
//  font-weight: 400;
//}

// scrollbar chrome //
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
*::-webkit-scrollbar {
  background: transparent;
  height: vw(5px);
  //width: vw(5px);
  width: 0;
}

*::-webkit-scrollbar-track,
*::-ms-scrollbar-track-color {
  margin: vw(10px) !important;
  //background: #d9d1f9;
  background: transparent;
  box-shadow: 0 0 vw(6px) rgba(128, 128, 128, 0.16);
  border-radius: vw(35px);
}

*::-webkit-scrollbar-thumb {
  border-radius: vw(30px);
  //background: #54349f;
  background: transparent;
}

// img fluid //

.img-fluid {
  width: auto;
  //height: 100% !important;
}

ul {
  margin-bottom: 0 !important;
  li {
    list-style-type: none;
  }
}
