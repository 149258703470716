.sales-leaders-fold1-block__title {
  font-size: vw(24px);
  line-height: vw(36px);
  letter-spacing: vw(-0.750316px);
  color: #19191b;
  @include fontDmsBold;
}
.sales-leaders-fold1-block__contentRow__colm1__row {
  margin-bottom: vw(25px);
  padding-top: vw(25px);
}
.tech-leaders-fold1-block__contentRow__colm2__outer-div {
  position: relative;
  margin-left: vw(84px);
  //z-index: 99;
  //padding: vw(182px) 0 0 vw(170px);

  //&::after {
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  content: " ";
  //  background: #ffffff;
  //  box-shadow: 0 vw(2px) vw(24px) rgba(88, 126, 236, 0.15);
  //  border-radius: vw(16px);
  //  width: vw(703px);
  //  height: vw(875px);
  //  z-index: -1;
  //}
}
