.no-show-management-div {
  padding-left: vw(169px);
  padding-top: vw(93px);
  padding-right: vw(109px);
}
.font-50-poppins {
  font-size: vw(50px);
  line-height: vw(66px);
  //font-size: vw(55px);
  //line-height: vw(71px);
  letter-spacing: vw(1px);
  color: #091133;
  @include fontMedium;
}
.no-show-mail-img-div {
  width: vw(302px);
}
.font-36-DMsans-medium {
  //font-size: vw(41px);
  //line-height: vw(62px);
  font-size: vw(36px);
  line-height: vw(57px);
  letter-spacing: vw(-1.56316px);
  color: #19191b;
  @include fontDmsMedium;
  position: relative;
  &::after {
    position: absolute;
    width: vw(41px);
    height: vw(14px);
    content: "";
    background: #5454d4;
    border-radius: vw(44px);
    top: 35%;
    left: 0;
  }
}
.font-20-mulish--width {
  width: vw(1193px);
}
.font-20-mulish {
  //font-size: vw(25px);
  //line-height: vw(35px);
  font-size: vw(20px);
  line-height: vw(30px);
  @include fontMulishRegular;
}
.no-show-management-card {
  width: vw(1115px);
  background: white;
  margin-top: vw(50px);
  box-shadow: 0px vw(2px) vw(24px) rgba(88, 126, 236, 0.15);
  border-radius: vw(16px);
  padding: vw(32px) vw(30px) vw(37px) vw(39px);
}
.no-show-management-icon-div {
  //width: vw(85px);
  width: vw(62px);
  height: auto;
}
.font-24-dms-medium {
  //font-size: vw(29px);
  //line-height: vw(62px);
  font-size: vw(24px);
  line-height: vw(57px);
  letter-spacing: vw(-1.56316px);
  color: #5454d4;
  @include fontDmsMedium;
  padding-left: vw(13px);
}
.render-block-3-img-div {
  width: vw(215px);
}
.no-show-management-footer {
  padding-bottom: vw(600px);
  //  .footer-div {
  //    position: absolute;
  //    right: 0;
  //    bottom: 0;
  //    left: 0;
  //  }
}
/*===============================================
                new
==============================================*/
.new-no-show-management-fold1-img {
  //width: vw(700px);
  height: auto;
  margin-right: vw(20px);
  width: vw(500px);
}
.no-show-management-font-20mulish-semibold {
  //font-size: vw(25px);
  //line-height: vw(35px);
  font-size: vw(20px);
  line-height: vw(30px);
  @include fontMulishSemiBold;
  color: #262626;
  padding-left: vw(80px);
  position: relative;

  &::after {
    position: absolute;
    width: vw(41px);
    height: vw(14px);
    content: "";
    background: #5454d4;
    border-radius: vw(44px);
    top: 35%;
    left: 0;
  }
}
.no-show-management-card--management-1 {
  //width: vw(954px);
  width: vw(716px);
}
.font-20-mulish--no-show-management {
  padding-left: vw(90px);
  position: relative;
  color: #696871;

  &::before {
    content: "";
    width: vw(21px);
    height: vw(5px);
    background: #ffb149;
    border-radius: vw(44px);
    left: vw(40px);
    top: vw(13px);
    position: absolute;
  }
}
.no-show-management-card--management-2 {
  //width: vw(954px);
  width: vw(721px);
}
.no-show-management-result-div {
  padding: vw(22px) 0 vw(53px) vw(236px);
}
.no-show-management-loosing-div {
  padding-left: vw(69px);
}
.no-show-manageent-early-div {
  padding-left: vw(103px);
  padding-top: vw(10px);
  padding-bottom: vw(73px);
}
.no-show-management-font-36-dms-medium {
  //font-size: vw(41px);
  //line-height: vw(62px);
  font-size: vw(36px);
  line-height: vw(57px);
  letter-spacing: vw(-1.56px);
  color: #19191b;
  @include fontDmsMedium;
  padding-left: vw(50px);
  position: relative;

  &::after {
    position: absolute;
    width: vw(41px);
    height: vw(14px);
    content: "";
    background: #5454d4;
    border-radius: vw(44px);
    top: 35%;
    left: 0;
  }
}
.font-20-mulish-semibold {
  //font-size: vw(25px);
  //line-height: vw(35px);
  font-size: vw(20px);
  line-height: vw(30px);
  @include fontMulishSemiBold;
  padding-top: vw(19px);
  padding-left: vw(50px);
}
.font-20-mulish--no-show-management-result {
  &::before,
  &::after {
    display: none;
  }
}
.no-show-management-img-fold3-img {
  //width: vw(600px);
  width: vw(434px);
  height: auto;
}
.no-show-management-img-fold3-img-div {
  padding-left: vw(76px);
}
