body {
  position: relative;
  min-height: 100%;
}
.footer-div {
  //position: absolute;
  //right: 0;
  //bottom: 0;
  //left: 0;
  width: 100%;
  //min-height: vw(430px);
  //min-height: vw(312px);
  background: rgba(20, 63, 119, 1);
  color: #ffffff;
  //padding: vw(60px) vw(60px) vw(102px) vw(60px);
  padding: vw(44px) vw(83px) vw(17px) vw(83px);
}
.footer-logo {
  //width: vw(40px);
  height: auto;
  width: vw(174px);

  img {
    width: 100%;
    height: auto;
  }
}

.footer-17-font-bold {
  font-size: vw(17px);
  line-height: vw(22px);
  text-align: center;
  letter-spacing: vw(-0.231818px);
  mix-blend-mode: normal;
  color: rgba(255, 255, 255, 0.5);
  @include fontDms;
  padding-top: vw(30px);
}
.footer-15-regular {
  font-size: vw(15px);
  line-height: vw(20px);
  letter-spacing: vw(-0.204545px);
  color: #ffffff;
  @include fontDms;
  padding-right: vw(73px);
}
.footer-face-img-div {
  width: vw(15px);
  margin-right: vw(40px);
}
.footer-twitter-img-div {
  width: vw(22px);
}

.footer-insta-img-div {
  width: vw(22px);
  margin-left: vw(40px);
}

.footer-linkedin-img-div {
  width: vw(20.63px);
  height: auto;
  margin-left: vw(6px);
}

.footer-15-font-bold {
  font-size: vw(15px);
  line-height: vw(20px);
  letter-spacing: vw(-0.204545px);
  color: #ffffff;
  padding-top: vw(5px);
}
.footer-text1 {
  font-size: vw(38px);
  line-height: vw(49px);
  letter-spacing: vw(-0.204545px);
  color: #ffffff;
  @include fontDmsBold;
  margin-bottom: 0;
  padding-top: vw(12px);
}
.footer-btn {
  width: vw(211px);
  height: vw(66px);
  line-height: vw(66px);
  background: #ff7143;
  border-radius: vw(10px);
  font-size: vw(17px);
  letter-spacing: vw(-0.531474px);
  color: #ffffff;
  @include fontDmsBold;
  padding: 0;
  margin-top: vw(23px);
}
.footer-link-div {
  border-top: 1px solid #ffffff;
  padding-top: vw(27px);
  margin-top: vw(28px);
}
