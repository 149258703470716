.hrd-top-div {
  padding: vw(90px) vw(173px) 0 vw(173px);
}
.hrd-img-div {
  width: vw(930px);
  //width: vw(1000px);
}
.hrd-card-div {
  //padding: vw(134px) vw(90px) 0 vw(90px);
  padding: vw(134px) 0 0 0;
}
.staffing-card-img--hrd {
  margin-bottom: vw(30px);
}
.staffing-card-text-div--hrd {
  margin-bottom: vw(19px);
}
.staffing-card--hrd {
  margin-left: vw(45px) !important;
  margin-bottom: vw(100px) !important;
}
.hrd-fold3-div {
  padding-left: vw(112px);
  padding-right: vw(176px);
  padding-bottom: vw(560px);
  //padding-bottom: vw(60px);
}
.hrd-36-font {
  //font-size: vw(41px);
  //line-height: vw(47px);
  font-size: vw(36px);
  line-height: vw(42px);
  color: #5454d4;
  @include fontMulishBold;
}
.hrd-card {
  background: #ffffff;
  box-shadow: 0 vw(2px) vw(24px) rgba(88, 126, 236, 0.15);
  border-radius: vw(16px);
  padding: vw(23px) vw(70px) vw(29px) vw(40px);
  margin-bottom: vw(20px);
  position: relative;
  //width: vw(600px);
  //min-height: vw(190px);
  width: vw(479px);
  min-height: vw(277px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &::after {
    position: absolute;
    background: #79aef5;
    border-radius: vw(18px);
    content: "";
    left: 0;
    top: 15%;
    width: vw(6px);
    height: 70%;
  }
}
.hrd-card-text {
  //font-size: vw(29px);
  //line-height: vw(40px);
  line-height: vw(30px);
  font-size: vw(24px);
  //line-height: vw(25px);
  color: #091133;
  @include fontDmsBold;
  padding-bottom: vw(20px);
}
.hrd-card-desc {
  //font-size: vw(21px);
  //line-height: vw(30px);
  font-size: vw(16px);
  line-height: vw(25px);
  color: #5d6970;
  @include fontMulishRegular;
}
.hrd-fold2-img-div {
  //width: vw(577px);
  //width: vw(793px);
  width: vw(642px);
  //margin-left: vw(150px);
  margin-left: vw(36px);
}
.hrd-bg-div {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: vw(167px);
    left: -1%;
    height: vw(167px);
    top: 1%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/img/background/bg2.svg");
  }
  .font-24-DMsans-bold {
    color: rgba(25, 25, 27, 0.51);
  }
}
