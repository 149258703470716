.about-us-fold1-img-div {
  //width: vw(950px);
  width: vw(835px);
  height: auto;
  margin-top: vw(15px);
  margin-right: vw(65px);
}
.fold1-right-div {
  width: 50%;
}
.home-font-20-mulish--medium {
  color: #79aef5;
  @include fontMulishBold;
}
.about-fold2-dm-sans-24 {
  //font-size: vw(29px);
  //line-height: vw(62px);
  font-size: vw(24px);
  line-height: vw(57px);
  letter-spacing: vw(-1.56px);
  @include fontDmsMedium;
  color: #5454d4;
  padding-left: vw(20px);
}
.home-font-20-mulish--about {
  //width: vw(900px);
  width: vw(752px);
}
.about-fold2-img-div {
  padding-left: vw(100px);
}
.about-fold2-img {
  //width: vw(500px);
  //height: vw(800px);
  width: vw(385px);
}
