.home-new-first-fold-div {
  //padding-top: vw(126px);
  padding-top: vw(80px);
  padding-left: vw(58px);
  //padding-bottom: vw(43px);
  padding-bottom: vw(260px);
  position: relative;
}
.home-new-fold-one-title {
  font-size: vw(50px);
  line-height: vw(66px);
  letter-spacing: 1px;
  color: #091133;
  @include fontExtrabold;
}
.home-new-fold-one-para {
  font-size: vw(20px);
  line-height: vw(30px);
  color: #696871;
  @include fontMulishRegular;
  padding-top: vw(36px);
}
.home-new-blue-btn {
  width: vw(258px);
  height: vw(60px);
  margin-right: vw(13px);
  background: #5454d4;
  border-radius: vw(10px);
  font-size: vw(17px);
  line-height: vw(60px);
  text-align: center;
  letter-spacing: vw(-0.531474px);
  color: #ffffff;
  @include fontDmsBold;
}
.home-new-blue-btn--green {
  width: vw(211px);
  background: #00ccbe;
}
.home-new-fold-one-img-div {
  //width: vw(605px);
  width: vw(816px);
  height: auto;
  //margin-top: vw(50px);
  position: absolute;
  right: 0;
  bottom: vw(90px);

  img {
    width: inherit;
    height: auto;
  }
}
.home-new-fold-one-talent-pipeline-div {
  background: #ecf3fa;
  padding: vw(76px) 0 vw(212px) 0;
}
.home-new-fold-one-talent-pipeline-para {
  font-size: vw(24px);
  line-height: vw(25px);
  color: rgba(0, 0, 0, 0.38);
  @include fontMulishSemiBold;
  //padding-top: vw(48px);
  padding-top: vw(21px);
}
.home-new-fold-one-technology-leader-div {
  padding: vw(28px) vw(101px) 0 vw(52px);
}
.home-new-fold-one-technology-leader-img-div {
  width: vw(624px);
  height: auto;
}
.home-new-fold-one-technology-card-div {
  padding-top: vw(104px);
}
.home-new-fold-one-technology-card-title {
  font-size: vw(36px);
  line-height: vw(36px);
  letter-spacing: vw(-0.750316px);
  color: #19191b;
}
.home-new-fold-one-check-icon {
  width: vw(33px);
  height: auto;
  margin-right: vw(30px);
}
.home-new-fold-one-technology-card-subtitle {
  font-size: vw(32px);
  line-height: vw(25px);
  color: rgba(0, 0, 0, 0.44);
  @include fontDmsMedium;
  padding-top: vw(17px);
}
.home-new-fold-one-technology-card-para {
  font-size: vw(18px);
  line-height: vw(35px);
  color: rgba(25, 25, 27, 0.97);
  @include fontMulishRegular;
  padding-top: vw(17px);
  width: vw(567px);
}
.home-new-fold-one-technology-card-link {
  font-size: vw(17px);
  line-height: vw(22px);
  letter-spacing: -0.531474px;
  color: #5454d4;
  @include fontDmsBold;
  margin-top: vw(17px);
  cursor: pointer;
}
/*===========================================
             home fold two
============================================*/
.home-new-fold-two-div {
  padding-top: vw(93px);
}
.home-new-fold-two-para {
  font-size: vw(18px);
  line-height: vw(27px);
  @include fontMulishRegular;
  color: rgba(25, 25, 27, 0.68);
}
.home-fold-two-card {
  margin-right: vw(40px);
  text-align: left;
}
.home-fold-one-card-outer-div {
  padding: vw(94px) vw(80px) vw(66px) vw(139px);
}
.home-new-fold-two-card-img1 {
  width: vw(58px);
  height: auto;
  margin-bottom: vw(34px);
}
.home-new-fold-two-card-img2 {
  width: vw(70px);
  height: auto;
  margin-bottom: vw(34px);
}
.home-fold-two-card-title {
  font-size: vw(24px);
  line-height: vw(36px);
  letter-spacing: vw(-0.750316px);
  color: #19191b;
  @include fontDmsBold;
}
.home-fold-two-card-para {
  // font-size: vw(16px);
  font-size: vw(18px);
  line-height: vw(25px);
  color: rgba(25, 25, 27, 0.68);
  @include fontMulishRegular;
  width: vw(381px);
}
.home-fold-two-card-para--one {
  width: vw(332px);
}
.home-new-fold-two-confidience-text1 {
  font-size: vw(36px);
  line-height: vw(44px);
  letter-spacing: -0.750316px;
  color: #19191b;
  @include fontDmsBold;
  width: vw(484px);
}
.home-new-fold-confidence-div {
  padding-bottom: vw(118px);
  padding-top: vw(72px);
}
.home-fold-two-block-two-img {
  //width: vw(677px);
  width: vw(700px);
  height: auto;
}
.home-new-fold-two-btn {
  border: vw(2px) solid #6c89f7;
  box-sizing: border-box;
  border-radius: vw(84px);
  width: vw(392px);
  height: vw(60px);
  background: white;
  font-size: vw(17px);
  line-height: vw(22px);
  text-align: center;
  letter-spacing: vw(-0.531474px);
  color: #6c89f7;
  margin: auto;
  @include fontDmsBold;
}
/*=============================================
              home fold three
==============================================*/
.home-new-fold-three {
  padding-top: vw(112px);
  background: rgba(235, 233, 237, 0.34);
}
.home-new-fold-three-step-one {
  //width: vw(466px);
  width: vw(533px);
  height: auto;
  margin-right: vw(159px);
  // margin-right: vw(105px);
}
.home-new-fold-three-step-number {
  font-size: vw(64px);
  line-height: vw(57px);
  letter-spacing: vw(-1.56316px);
  color: #67a4f4;
  @include fontDmsMedium;
}
.home-new-fold-three-step-title {
  font-size: vw(36px);
  line-height: vw(48px);
  letter-spacing: vw(-0.750316px);
  color: #19191b;
  @include fontDmsMedium;
  width: vw(619px);
}
.home-fold-two-card-para--fold-three {
  width: vw(642px);
}
.home-new-fold-three-step-2-div {
  padding: vw(84px) vw(14px) vw(104px) vw(115px);
}

.home-new-fold-three-step-two-img {
  //width: vw(540px);
  width: vw(568px);
  height: auto;
  margin-top: vw(20px);
}
.home-new-fold-three-step-three-div {
  //padding: 0 vw(149px) vw(179px) vw(161px);
  padding: 0 vw(108px) vw(77px) 0;
}
.home-new-fold-three-step-three {
  //width: vw(278px);
  width: vw(660px);
  height: auto;
  // box-shadow: 0 vw(4px) vw(21px) rgba(0, 0, 0, 0.06);
  // border-radius: vw(15px);
  // margin-bottom: vw(20px);
}
.home-new-fold-three-step-four-img {
  width: vw(601px);
  height: auto;
}
.home-new-fold-three-step-four-div {
  padding-left: vw(115px);
  // padding-bottom: vw(202px);
  padding-bottom: vw(84px);
}
.home-new-fold-three-step-five {
  width: vw(733px);
  height: auto;
}
.home-new-fold-three-step-five-div {
  padding-bottom: vw(180px);
  padding-right: vw(40px);
  padding-top: vw(53px);
}
/*============================================
                 home fold four
============================================*/

.home-new-slider-div {
  padding-top: vw(298px);
  padding-bottom: vw(124px);
}
.home-new-fold-four-client-img {
  width: vw(219px);
  height: auto;
  margin-right: vw(44px);
}
.home-new-fold-four-client-name {
  font-size: vw(32px);
  line-height: vw(25px);
  @include fontDmsMedium;
}
.home-new-fold-four-client-company-name {
  font-size: vw(24px);
  line-height: vw(25px);
  @include fontDmsMedium;
  padding-top: vw(28px);
}
.home-new-fold-four-client-position {
  font-size: vw(32px);
  line-height: vw(25px);
  color: rgba(0, 0, 0, 0.53);
  @include fontDmsMedium;
  padding-top: vw(31px);
}
.home-new-fold-four-client-description {
  font-size: vw(16px);
  line-height: vw(33px);
  color: rgba(25, 25, 27, 0.68);
  @include fontMulishRegular;
  font-style: italic;
  width: vw(572px);
  position: relative;
  margin-top: vw(20px);

  //&::after {
  //  position: absolute;
  //  content: url("/img/icon/opening-quote.svg");
  //  left: vw(-40px);
  //  top: vw(-20px);
  //width: vw(35px);
  //height: vw(35px);
  //  z-index: 99;
  // }
}
.home-new-fold-four-slide {
  padding-right: vw(74px);
  padding-left: vw(84px);
  padding-bottom: vw(112px);
}
.opening-quote,
.closing-quote {
  position: absolute;
  width: vw(35px);
}
.opening-quote {
  top: vw(-20px);
  left: vw(-40px);
}
.closing-quote {
  bottom: vw(-20px);
  right: vw(-20px);
}
.carousel__dot-group {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .carousel__dot {
    width: vw(10px);
    height: vw(10px);
    border-radius: 100%;
    margin-right: vw(11px);
    padding: 0;
    span {
      display: none;
    }
  }
  .carousel__dot--selected {
    width: vw(13px);
    height: vw(13px);
    background: #143f77;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
