.assessments-div {
  padding: vw(53px) vw(141px) 0 vw(174px);
}
.no-show-management-card--tittle {
  //width: vw(890px) !important;
  width: vw(685px) !important;
  padding-top: vw(16px);
}
.assessments-fold1-img-div {
  // width: vw(700px);
  width: vw(560px);
  height: auto;
}
.assessments-fold2-img-div {
  //width: vw(628px);
  width: vw(457px);
  height: auto;
  margin-right: vw(117px);
}
.no-show-management-card--assessments-fold2 {
  padding-top: vw(16px) !important;
  //width: vw(749px) !important;
  width: vw(562px) !important;
}
.assessments-fold3-img-div {
  //width: vw(701px);
  width: vw(509px);
  height: auto;
}
.no-show-management-card--assessments-fold3 {
  padding-top: vw(16px);
  //width: vw(985px) !important;
  width: vw(716px) !important;
  z-index: 99;
  position: absolute;
  top: 15%;
  left: 0;
}
.assessments-icon-block {
  width: auto;
  height: vw(220px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: vw(30px);
}
.assessment-img1 {
  //width: vw(184px);
  width: vw(134px);
  height: auto;
}
.assessment-img2 {
  //width: vw(184px);
  width: vw(134px);
  height: auto;
}
.assessment-img3 {
  //width: vw(184px);
  width: vw(134px);
  height: auto;
}
.assessments-icons-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-text {
  font-size: vw(29px);
  line-height: vw(62px);
  letter-spacing: vw(-1.56316px);
  color: #262634;
  @include fontDmsMedium;
}
.assessments-fold5-img-div {
  //width: vw(524px);
  width: vw(397px);
}
.most-popular-test-outer-div {
  width: 60%;
  //padding-left: vw(200px);
  padding-left: vw(180px);
}
.most-popular-test-inner-div {
  width: vw(220px);
  //width: vw(350px);
  height: vw(64px);
  //height: auto;
  background: #ffffff;
  box-shadow: 0 vw(2px) vw(24px) rgba(88, 126, 236, 0.15);
  border-radius: vw(8px);
  padding-left: vw(25px);
  margin-left: vw(10px);
  margin-right: vw(10px);
  margin-bottom: vw(10px);
  position: relative;

  &::after {
    position: absolute;
    width: vw(5px);
    height: 70%;
    content: "";
    background: #79aef5;
    border-radius: vw(18px);
    left: 0;
    top: vw(10px);
  }
}
.most-popular-test-text {
  //font-size: vw(25px);
  //line-height: vw(62px);
  font-size: vw(20px);
  line-height: vw(57px);
  letter-spacing: vw(-1.56316px);
  color: #262634;
  @include fontDmsMedium;
}
.font-20-mulish-grey {
  color: #696871;
  padding-top: vw(20px);
}
.font-17-dms {
  font-size: vw(22px);
  line-height: vw(27px);
  letter-spacing: vw(-0.531474px);
  color: #5454d4;
  @include fontDmsBold;
  padding-top: vw(25px);
}
.assessments-fold6-img-div {
  //width: vw(706px);
  width: vw(514px);
}

.assessments-footer-div {
  padding-top: vw(500px);
}

.font-20-mulish--font-bold {
  @include fontMulishExtraBold;
  font-size: vw(20px);
  //font-size: vw(25px);
  //line-height: vw(35px);
  line-height: vw(30px);
}

.no-show-management-card--assessments-advantages {
  //width: vw(900px) !important;
  width: vw(616px) !important;
  padding: vw(33px) 0 vw(35px) vw(36px) !important;
  position: relative;
  margin-top: vw(55px) !important;

  &::before {
    position: absolute;
    width: vw(5px);
    height: 70%;
    content: "";
    background: #79aef5;
    border-radius: vw(18px);
    top: vw(16px);
    left: vw(-3px);
  }
}
.assessment-type-test-div {
  // padding-right: vw(300px);
  padding-right: vw(100px);
}
