// fold1
.tech-leaders-fold1-block {
  padding: vw(206px) vw(113px) vw(67px) vw(122px);
}
.tech-leaders-fold1-title {
  font-size: vw(50px);
  line-height: vw(66px);
  @include fontMedium;
  margin-bottom: 0;
  letter-spacing: vw(1px);
  color: #091133;
}
.tech-leaders-fold1-block__contentRow {
  padding-top: vw(89px);
}
.tech-leaders-fold1-block__contentRow__colm1 {
  width: vw(595px);
  margin-right: vw(134px);
}
.tech-leaders-fold1-block__contentRow__colm2 {
  width: vw(486px);
  //height: vw(840px);
  //background: #c4c4c4;
  // display: inline-flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  padding-top: vw(50px);
}
.calendarly-img {
  width: vw(458px);
  height: auto;
}
.calendarly-link-btn {
  width: vw(280px);
  height: vw(60px);
  margin-top: vw(37px);
  background: #ff7143;
  border-radius: vw(10px);
  font-size: vw(17px);
  line-height: vw(60px);
  text-align: center;
  letter-spacing: vw(-0.531474px);
  @include fontDmsBold;
  color: #ffffff;
}
.tech-leaders-fold1-block__iconBlock {
  width: vw(72px);
  margin-right: vw(28px);

  img {
    height: auto;
    margin: 0 auto;
  }
}
.tech-leaders-fold1-block__iconBlock__img1 {
  width: vw(72px);
}
.tech-leaders-fold1-block__iconBlock__img2 {
  width: vw(70px);
}
.tech-leaders-fold1-block__iconBlock__img3 {
  width: vw(50px);
}
.tech-leaders-fold1-block__iconBlock__img4 {
  width: vw(50px);
}
.tech-leaders-fold1-block__title {
  font-size: vw(36px);
  line-height: vw(36px);
  @include fontDmsBold;
  margin-bottom: vw(13px) !important;
  letter-spacing: vw(-0.75px);
  color: #19191b;
}
.tech-leaders-fold1-block__desc {
  font-size: vw(16px);
  line-height: vw(25px);
  @include fontMulishRegular;
  margin-bottom: 0;
  color: rgba(25, 25, 27, 0.68);
}
.tech-leaders-fold1-block__contentRow__colm1__row {
  margin-bottom: vw(81px);
}

// process fold
.tech-leaders-process-fold {
  background: #ecf3fa;
  padding: vw(80px) vw(100px) vw(79px) vw(100px);
}
.tech-leaders-process-fold__contentRow {
  padding-top: vw(117px);
}
.tech-leaders-process-fold__contentRow__colm1,
.tech-leaders-process-fold__contentRow__colm2 {
  width: vw(326px);
}
.tech-leaders-process-fold__contentRow__imgBlock {
  width: vw(70px);
  height: vw(70px);

  img {
    width: inherit;
    height: inherit;
  }
}
.tech-leaders-process-fold__contentRow__colm1 {
  margin-right: vw(152px);
  .tech-leaders-process-fold__contentRow__imgBlock {
    margin: 0 auto vw(54px) auto;
  }
}
.tech-leaders-process-fold__contentRow__colm2 {
  .tech-leaders-process-fold__contentRow__imgBlock {
    margin-left: vw(96px);
    margin-bottom: vw(48px);
  }
}
.tech-leaders-process-fold-title {
  font-size: vw(50px);
  line-height: vw(57px);
  text-align: center;
  letter-spacing: vw(-1.56316px);
  color: #19191b;
  @include fontDmsBold;
  padding-bottom: vw(13px);
}

// approach fold
.tech-leaders-approach-fold {
  padding: vw(152px) vw(110px) vw(142px) vw(110px);
}
.tech-leaders-approach-fold__desc {
  padding-top: vw(9px);
  font-size: vw(18px);
  line-height: vw(27px);
  @include fontMulishSemiBold;
  text-align: center;
  color: rgba(25, 25, 27, 0.68);
}
.tech-leaders-approach-fold__contentRow1 {
  padding-top: vw(93px);
}
.tech-leaders-approach-fold__contentRow2 {
  padding-top: vw(75px);
}
.tech-leaders-approach-fold__contentRow__colm {
  width: vw(513px);
}
.tech-leaders-approach-fold__contentRow__imgBlock1,
.tech-leaders-approach-fold__contentRow__imgBlock2,
.tech-leaders-approach-fold__contentRow__imgBlock3,
.tech-leaders-approach-fold__contentRow__imgBlock4 {
  width: auto;
  height: vw(70px);
  margin-bottom: vw(55px);

  img {
    width: inherit;
    height: inherit;
  }
}

// get started fold
.tech-leaders-get-started-fold {
  padding-top: vw(119px);
  padding-bottom: vw(151px);
  background-color: #f8f8f9;
}

// specialize in fold
.tech-leaders-specialize-in-fold {
  padding: vw(120px) vw(160px) vw(186px) vw(160px);
}
.tech-leaders-specialize-in-fold__listContent {
  padding-top: vw(75px);

  ul {
    margin-right: vw(189px);
  }

  li {
    font-size: vw(18px);
    line-height: vw(25px);
    @include fontDmsBold;
    color: #091133;
    margin-bottom: vw(32px) !important;

    img {
      width: vw(33px);
      height: auto;
      margin-right: vw(17px);
    }
  }
}
.tech-leaders-specialize-in-fold__imgBlock {
  padding-top: vw(53px);
  text-align: center;
  img {
    height: auto;
  }
  .tech-leaders-specialize-in-fold__imgBlock__img1 {
    width: vw(80px);
    margin-right: vw(69px);
    margin-bottom: vw(28px);
  }
  .tech-leaders-specialize-in-fold__imgBlock__img2 {
    width: vw(48px);
    margin-bottom: vw(24px);
  }
  .tech-leaders-specialize-in-fold__imgBlock__img3 {
    width: vw(60px);
    margin-right: vw(42px);
  }
  .tech-leaders-specialize-in-fold__imgBlock__img4 {
    width: vw(138px);
    margin-right: vw(47px);
  }
  .tech-leaders-specialize-in-fold__imgBlock__img5 {
    width: vw(64px);
  }
}

// footer
.web-footer-div-tech-leaders-page {
  //padding-top: vw(430px);
  padding-top: vw(186px);
}

.tech-leaders-process-fold__listContent__colm1,
.tech-leaders-process-fold__listContent__colm2 {
  h3 {
    padding-bottom: vw(41px);
  }
}

.tech-leaders-process-fold__listContent__colm1 {
  margin-right: vw(359px);
  padding-left: vw(115px);
}

.sales-leaders-specialize-in-fold {
  padding: vw(81px) vw(33px) vw(186px) vw(33px);
}

.tech-leaders-specialize-in-fold-card {
  width: vw(264px);
  height: auto;
  margin-bottom: vw(63px);
}
.tech-leaders-specialize-in-fold-card-img {
  width: 100%;
  height: vw(79px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: vw(26px);
}
.dev-ops-icon {
  width: vw(80px);
  height: auto;
}
.node-js-icon {
  width: vw(158px);
  height: auto;
}
.php-icon {
  width: vw(63px);
  height: auto;
}
.android-icon {
  width: vw(105px);
}
.java-icon {
  width: vw(76px);
  height: auto;
}
.java-script-icon {
  width: vw(91px);
  height: auto;
}
.angularjs-icon {
  width: vw(64px);
  height: auto;
}
.python-icon {
  width: vw(48px);
  height: auto;
}
.dba-icon {
  width: vw(84px);
  height: auto;
}
.ai-ml-icon {
  width: vw(76px);
  height: auto;
}
.data-science-icon {
  width: vw(51px);
  height: auto;
}
.ui-ux-icon {
  width: vw(76px);
  height: auto;
}
.golang-icon {
  width: vw(116px);
  height: auto;
}
.qa-icon {
  width: vw(85px);
  height: auto;
}
.system-analyst-icon {
  width: vw(101px);
  height: auto;
}
.aws-icon {
  width: vw(93px);
  height: auto;
}
.tech-leaders-specialize-in-fold-card-text {
  font-size: vw(18px);
  line-height: vw(25px);
  color: #091133;
  @include fontDmsBold;
  margin-bottom: 0;
}
