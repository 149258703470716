.contact-top-div {
  padding-top: vw(90px);
  padding-left: vw(173px);
  //padding-right: vw(232px);
  padding-right: vw(154px);
  //padding-bottom: vw(869px);
  padding-bottom: vw(369px);
  //padding-bottom: vw(1452px);
  min-height: 85vh;
}
.contact-label {
  font-size: vw(23px);
  line-height: vw(30px);
  color: #091133;
  @include fontDmsBold;
}
label {
  //font-size: vw(23px);
  //line-height: vw(30px);
  font-size: vw(18px);
  line-height: vw(25px);
  color: #091133;
  @include fontDmsBold;
  margin-bottom: 0 !important;
}
.contact-input-div {
  //width: vw(635px);
  margin-top: vw(10px);
  //height: vw(70px);
  width: vw(462px);
  height: vw(56px);

  textarea,
  input {
    width: 100%;
    height: 100%;
    background: rgba(148, 195, 255, 0.23);
    border-radius: vw(8px);
    border: none;
    padding-left: vw(25px);
    padding-right: vw(30px);
    //font-size: vw(21px);
    //line-height: vw(30px);
    color: #5d6970;
    @include fontMulishRegular;
    font-size: vw(16px);
    line-height: vw(25px);

    &::placeholder {
      //font-size: vw(21px);
      //line-height: vw(30px);
      color: rgba(93, 105, 112, 0.8);
      @include fontMulishRegular;
      font-size: vw(16px);
      line-height: vw(25px);
    }
  }
}
.contact-input-div--message {
  //width: 100% !important;
  height: vw(200px);
  textarea {
    padding-top: vw(30px);
    padding-bottom: vw(30px);
  }
}

.contact-us-form-div {
  width: 50%;
  padding-left: vw(50px);
}

.job-img {
  width: vw(40px);
  height: auto;
}
.link-img {
  width: vw(26px);
  height: auto;
}
.contact-left-title {
  //font-size: vw(29px);
  //line-height: vw(31px);
  font-size: vw(24px);
  line-height: vw(26px);
  color: #091133;
  @include fontDmsBold;
  padding-bottom: vw(24px);
}
.contact-left-link {
  //font-size: vw(23px);
  //line-height: vw(31px);
  font-size: vw(18px);
  line-height: vw(26px);
  color: #156bdd;
  text-decoration: underline;
  padding-left: vw(22px);
}
.star-img {
  width: vw(42px);
  height: auto;
}
.map-img {
  width: vw(42px);
  height: auto;
}

.contact-left-img-div {
  margin-right: vw(33px);
}

.location-img {
  width: vw(26px);
  height: auto;
  margin-right: vw(21px);
}

.contact-left-text-3 {
  //font-size: vw(21px);
  //line-height: vw(30px);
  font-size: vw(16px);
  line-height: vw(21px);
  @include fontMulishRegular;
  color: #5d6970;
}

.contact-btn-div {
  padding-top: vw(50px);
  //padding-right: vw(70px);
}

.contact-input-div--pnumber-div {
  display: flex;
  align-items: flex-end;
  //width: vw(635px);
  width: vw(462px);
  margin-top: vw(10px);
  //height: vw(70px);
  height: vw(56px);
  border-radius: vw(8px);
  background: rgba(148, 195, 255, 0.18);
}

.contact-input-div--pnumber {
  width: 80%;
  height: 100%;
  input {
    background: transparent;
  }
}

.contact-input-div--c-code {
  height: inherit;
  .react-tel-input .form-control {
    max-width: 100%;
    position: relative;
    //color: #9578b1 !important;
    //font-size: vw(18px);
    //line-height: vw(22px);
    font-size: vw(16px);
    line-height: vw(25px);
    letter-spacing: vw(0.41px);
    box-shadow: none;
    z-index: 0;
    border: 0 !important;
    border-radius: 0 !important;
    //height: vw(70px);
    //width: vw(30px);
    width: vw(80px);
    height: vw(56px);
    outline: none !important;
    background: rgba(148, 195, 255, 0.23) !important;
    border-radius: vw(8px) !important;
    padding-left: vw(65px);

    &:hover {
      background: transparent;
    }
  }

  .react-tel-input .form-control:focus {
    outline: none !important;
    box-shadow: none;
  }

  .react-tel-input .country-list {
    text-align: left;
    margin-top: vw(15px);
    //width: fit-content;
    background: white !important;
  }

  .react-tel-input .flag-dropdown {
    border: none;
    background-color: transparent;
    background: transparent;
    //height: vw(30px);
  }

  .react-tel-input .flag-dropdown:hover,
  .react-tel-input .flag-dropdown:focus {
    border: none !important;
    background: transparent !important;
  }

  .react-tel-input .flag-dropdown.open .selected-flag {
    background: transparent !important;
  }
  .selected-flag:hover,
  .country:hover {
    background: transparent !important;
  }
  .country-list .country {
    border: none;
    outline: none;
  }
  .react-tel-input .selected-flag {
    position: relative;
    //width: vw(86px) !important;
    width: vw(80px) !important;
    height: 100%;
    //padding: vw(33px) vw(30px) vw(10px) vw(30px);
    padding: vw(33px) vw(25px) vw(10px) vw(25px);
    border-radius: 0;
    border: 0 !important;
  }
}
