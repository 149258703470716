.choose-us-fold-one {
  padding-bottom: vw(210px);
  background-image: url("/img/why-choose-us/fold-one-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
  background-position: left;
  color: white;
}
.choose-us-text1 {
  font-size: vw(48px);
  line-height: vw(69px);
  @include fontMulishExtraBold;
  margin-bottom: 0;
}
.choose-us-text2 {
  font-size: vw(24px);
  line-height: vw(44px);
  @include fontMulishSemiBold;
  margin-bottom: 0;
}
/*=======================================================
                     fold two
=========================================================*/
.choose-us-fold-two-section {
  padding-top: vw(103px);
}
.choose-us-fold-two-title {
  font-size: vw(50px);
  line-height: vw(66px);
  text-align: center;
  letter-spacing: w(1px);
  color: #091133;
  margin-bottom: 0;
  @include fontMedium;
}
.choose-us-fold-two-block-one {
  padding-top: vw(103px);
  padding-left: vw(22px);
}
.choose-us-fold-two-img-one {
  width: vw(591px);
  height: auto;
  margin-right: vw(92px);
}
.choose-us-fold-two-text1 {
  font-size: vw(50px);
  line-height: vw(61px);
  letter-spacing: vw(-1.56316px);
  color: #53535b;
  @include fontDmsMedium;
}
.choose-us-fold-two-text2 {
  font-size: vw(24px);
  line-height: vw(41px);
  color: rgba(0, 0, 0, 0.38);
  @include fontMulishSemiBold;
  width: vw(653px);
}
.choose-us-fold-two-block-two {
  padding-top: vw(196px);
  padding-left: vw(50px);
}
.choose-us-fold-two-img-two {
  width: vw(690px);
  height: auto;
}
.choose-us-fold-two-block-three {
  padding-top: vw(112px);
  padding-right: vw(10px);
}
.choose-us-fold-two-img-three {
  width: vw(660px);
  height: auto;
}
.choose-us-fold-two-block-four {
  padding-top: vw(89px);
  padding-left: vw(67px);
  padding-bottom: vw(74px);
}
.choose-us-fold-two-img-four {
  width: vw(677px);
  height: auto;
}
/*============================================
     fold three
=============================================*/
.choose-us-fold-three-section {
  background: rgba(235, 233, 237, 0.34);
  padding: vw(108px) vw(62px) vw(108px) vw(62px);
}
.choose-us-fold-three-content-section {
  padding-top: vw(130px);
  padding-left: vw(50px);
}
.choose-us-fold-three-img {
  width: vw(654px);
  height: auto;
}
.choose-fold-three-text1 {
  font-size: vw(64px);
  line-height: vw(57px);
  letter-spacing: -1.56316px;
  color: #67a4f4;
  @include fontDmsMedium;
  margin-bottom: 0;
}
.choose-fold-three-text2 {
  font-size: vw(24px);
  line-height: vw(41px);
  color: rgba(0, 0, 0, 0.38);
  @include fontMulishSemiBold;
  margin-bottom: 0;
  padding-top: vw(15px);
  width: vw(445px);
}
.choose-blue-check-icon {
  width: vw(33px);
  margin-top: vw(12px);
}
.choose-fold-three-line {
  border-left: 1px dashed rgba(0, 0, 0, 0.16);
  width: vw(1px);
  height: vw(140px);
  position: absolute;
  left: 50%;
  top: 93%;
}
.choose-fold-three-check-div {
  margin-right: vw(25px);
  position: relative;
}
