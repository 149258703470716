.about-us-new-fold-one-main-section {
  position: relative;
  z-index: 99;
  padding-bottom: vw(106px);

  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: vw(535px);
    background: rgba(251, 224, 185, 0.26);
    z-index: -1;
  }
}
.about-us-new-fold-one-section {
  padding-top: vw(130px);
  padding-right: vw(105px);
}
.about-us-new-fold-one-img {
  //width: vw(842px);
  width: vw(700px);
  height: auto;
  margin-right: vw(74px);
}
.about-us-new-fold-one-title {
  font-size: vw(50px);
  line-height: vw(66px);
  letter-spacing: vw(1px);
  color: #091133;
  @include fontMedium;
}

.about-us-new-fold-one-para {
  font-size: vw(18px);
  line-height: vw(36px);
  letter-spacing: vw(1px);
  color: #000000;
  @include fontMedium;
}
.about-us-new-fold-one-para-two {
  font-size: vw(20px);
  line-height: vw(30px);
  color: #696871;
  @include fontMulishRegular;
}
.about-us-new-fold-one-block-one {
  padding-top: vw(74px);
}
.about-us-new-fold-one-block-one-text1 {
  font-size: vw(24px);
  line-height: vw(57px);
  letter-spacing: vw(-1.56316px);
  color: rgba(25, 25, 27, 0.51);
  @include fontDmsBold;
}

.about-us-new-fold-one-block-one-text2 {
  font-size: vw(36px);
  line-height: vw(57px);
  letter-spacing: vw(-1.56316px);
  color: #5454d4;
  @include fontDmsMedium;
}
.godrej-logo {
  width: vw(216px);
  height: auto;
  margin-right: vw(66px);
}
.bosch-logo {
  width: vw(182px);
  height: auto;
  margin-right: vw(66px);
}
.panasonic-logo {
  width: vw(182px);
  height: auto;
  margin-right: vw(66px);
}
.monta-vista-logo {
  width: vw(112px);
  height: auto;
  margin-right: vw(66px);
}
.shoptimize-logo {
  width: vw(185px);
  height: auto;
  margin-right: vw(66px);
}
.communitybrands-logo {
  width: vw(166px);
  height: auto;
}
.toppr-logo {
  width: vw(190px);
  height: auto;
  margin-right: vw(30px);
}
.spotonbig-logo {
  width: vw(205px);
  height: auto;
  margin-right: vw(30px);
}
.empire-logo {
  width: vw(170px);
  height: auto;
  margin-right: vw(60px);
}
.hussman-logo {
  width: vw(161px);
  height: auto;
}
.handg-logo {
  width: vw(131px);
  height: auto;
  margin-right: vw(91px);
}
.shoppers-stop-logo {
  width: vw(125px);
  height: auto;
  margin-right: vw(91px);
}
.company-logo {
  width: vw(112px);
  height: auto;
  margin-right: vw(91px);
}
.applied-ai-logo {
  width: vw(118px);
  height: auto;
}
/*=========================================================
                  fold two
==========================================================*/
.about-us-new-fold-two-section {
  padding: vw(40px) vw(171px) vw(93px) vw(200px);
  position: relative;
  z-index: 99;

  &::after {
    width: 100%;
    content: "";
    position: absolute;
    height: 82%;
    top: 0;
    left: 0;
    background: #f8f8f9;
    z-index: -1;
  }
  .img-block-div {
    height: vw(220px);
  }
  .our-service-card {
    width: vw(339px);
    padding-bottom: vw(35px);
  }
}
.our-service-card--about-us {
  width: vw(1069px) !important;
  min-height: vw(230px);
  margin-top: vw(58px);
  padding: vw(55px) vw(54px) vw(44px) vw(52px);
}
.our-service-card--about-us-text1 {
  font-size: vw(36px);
  line-height: vw(47px);
  letter-spacing: vw(-0.531474px);
  @include fontDmsBold;
  color: #19191b;
  text-align: left;
}
.about-us-new-aspire-candidate-img {
  width: vw(127px);
  height: auto;
}
/*==========================================================================
                        about fold three
==========================================================================*/
.about-us-new-fold-three-section {
  padding-left: vw(180px);
  padding-right: vw(121px);
}
.about-us-new-fold3-img {
  width: vw(396px);
  height: auto;
}
/*=========================================================================
                     about fold four
==========================================================================*/
.about-us-new-fold-four-section {
  background: rgba(251, 224, 185, 0.26);
  padding: vw(100px) vw(126px) vw(117px) vw(102px);
  .font-50-poppins {
    display: none;
  }
  .pt-74 {
    padding: 0;
  }
}
.about-us-new-fold-four-text1 {
  font-size: vw(50px);
  line-height: vw(66px);
  letter-spacing: vw(1px);
  color: #6c89f7;
  @include fontBold;
  margin-bottom: 0;
}
.about-us-new-fold-four-text2 {
  font-size: vw(24px);
  line-height: vw(66px);
  letter-spacing: vw(1px);
  color: rgba(9, 17, 51, 0.54);
  @include fontMedium;
}
.about-us-new-fold-four-btn {
  background: #ff7143;
  border-radius: vw(10px);
  width: vw(211px);
  height: vw(60px);
  font-size: vw(17px);
  line-height: vw(60px);
  text-align: center;
  letter-spacing: vw(-0.531474px);
  color: #ffffff;
  margin-top: vw(20px);
  @include fontDmsBold;
}
