@media screen and (max-width: 430px) {
  .home-new-fold-one-img-div {
    width: vw(780px);
  }
}
/*===================================
        mobile view
===================================*/
@media screen and (max-width: 767px) {
  .footer-btn {
    letter-spacing: mvwDivide(-0.531474px);
  }
}
