.aspire-navbar {
  //padding: vw(70px) vw(95px) 0 vw(50px);
  //padding: vw(64px) vw(47px) 0 vw(50px);
  padding: vw(43px) vw(72px) 0 vw(58px);
  li {
    //padding-right: vw(70px);
    padding-right: vw(29px);
  }
  .nav-link {
    //font-size: vw(18px);
    //line-height: vw(20px);
    //font-size: vw(23px);
    //line-height: vw(25px);
    letter-spacing: vw(-0.468947px);
    color: #696871;
    padding: 0;
    padding-bottom: vw(7px);
    font-size: vw(15px);
    line-height: vw(20px);
    @include fontDmsBold;
  }
}
.nav-link--active {
  position: relative;

  color: #19191b !important;

  &::after {
    content: "";
    //width: 50%;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    position: absolute;
    background: rgba(84, 84, 212, 1);
  }
}
.web-navbar-button {
  //width: vw(211px);
  //width: vw(216px);
  width: vw(287px);
  height: vw(60px);
  background: #ff7143;
  border-radius: vw(10px);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.web-navbar-button-text {
  font-size: vw(17px);
  line-height: vw(60px);
  //font-size: vw(22px);
  //line-height: vw(26px);
  text-align: center;
  letter-spacing: vw(-0.531474px);
  @include fontDmsBold;
}
.aspire-header-logo {
  width: vw(190px);
  height: auto;
}
.nav-link--our-test {
  color: #6c89f7 !important;
}
