/*===================== padding top =============*/
.pt-10 {
  padding-top: vw(10px);
}
.pt-16 {
  padding-top: vw(16px);
}
.pt-18 {
  padding-top: vw(18px);
}
.pt-20 {
  padding-top: vw(20px);
}
.pt-23 {
  padding-top: vw(23px);
}
.pt-25 {
  padding-top: vw(25px);
}
.pt-30 {
  padding-top: vw(30px);
}
.pt-36 {
  padding-top: vw(36px);
}
.pt-38 {
  padding-top: vw(38px);
}
.pt-40 {
  padding-top: vw(40px);
}
.pt-44 {
  padding-top: vw(44px) !important;
}
.pt-50 {
  padding-top: vw(50px);
}
.pt-54 {
  padding-top: vw(54px);
}
.pt-60 {
  padding-top: vw(60px);
}
.pt-63 {
  padding-top: vw(63px);
}
.pt-67 {
  padding-top: vw(67px);
}

.pt-72 {
  padding-top: vw(72px);
}
.pt-80 {
  padding-top: vw(80px);
}

.pt-90 {
  padding-top: vw(90px);
}
.pt-94 {
  padding-top: vw(94px);
}
.pt-100 {
  padding-top: vw(100px);
}
.pt-104 {
  padding-top: vw(104px);
}
.pt-116 {
  padding-top: vw(116px);
}
.pt-120 {
  padding-top: vw(120px);
}
.pt-125 {
  padding-top: vw(125px);
}

.pt-133 {
  padding-top: vw(133px);
}
.pt-135 {
  padding-top: vw(135px);
}
.pt-160 {
  padding-top: vw(160px);
}
.pt-200 {
  padding-top: vw(200px);
}
.pt-500 {
  padding-top: vw(500px);
}

/*================== padding left ===========*/
.pl-10 {
  padding-left: vw(10px);
}
.pl-20 {
  padding-left: vw(20px) !important;
}
.pl-30 {
  padding-left: vw(30px);
}
.pl-80 {
  padding-left: vw(80px);
}
.pl-50 {
  padding-left: vw(50px);
}
.pl-60 {
  padding-left: vw(60px);
}
.pl-74 {
  padding-left: vw(74px);
}
.pl-100 {
  padding-left: vw(100px) !important;
}
.pl-111 {
  padding-left: vw(111px);
}
.pl-121 {
  padding-left: vw(121px);
}
.pl-137 {
  padding-left: vw(137px);
}
.pl-141 {
  padding-left: vw(141px);
}
.pl-174 {
  padding-left: vw(174px);
}
.pl-200 {
  padding-left: vw(200px);
}
.pl-300 {
  padding-left: vw(300px);
}
/*==================  padding right  =============*/
.pr-44 {
  padding-right: vw(44px);
}
.pr-100 {
  padding-right: vw(100px);
}
.pr-115 {
  padding-right: vw(115px);
}
.pr-200 {
  padding-right: vw(200px);
}
.pr-212 {
  padding-right: vw(212px);
}

/*==================  padding bottom  =============*/
.pb-100 {
  padding-bottom: vw(100px);
}
.pb-180 {
  padding-bottom: vw(180px);
}
.pb-370 {
  padding-bottom: vw(370px);
}
.pb-1000 {
  padding-bottom: vw(1000px);
}

/*================== margin left ================*/
.ml-35 {
  margin-left: vw(35px);
}
.ml-44 {
  margin-left: vw(44px);
}
.ml-155 {
  margin-left: vw(110px);
}

/*================== margin top ================*/

.mt-10 {
  margin-top: vw(10px);
}
.mt-48 {
  margin-top: vw(48px);
}
.mt-80 {
  margin-top: vw(80px);
}
.mt-100 {
  margin-top: vw(100px);
}
/*================== home css ================*/
.column-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*================== margin bottom ================*/
.mb-200 {
  margin-bottom: vw(200px);
}
.mb-320 {
  margin-bottom: vw(320px);
}
.mb-330 {
  margin-bottom: vw(330px);
}
.mb-800 {
  margin-bottom: vw(800px);
}
/*================== margin right ================*/
.mr-15 {
  margin-right: vw(15px);
}
.mr-40 {
  margin-right: vw(40px);
}
.mr-50 {
  margin-right: vw(50px);
}
.mr-130 {
  margin-right: vw(130px);
}

.bg-div {
  background-image: url("/img//background/bg-img.png");
  background-position: left;
  background-repeat: no-repeat;
  background-clip: padding-box;
  background-size: cover;
}
/*================== common ==============*/
.go-back-img {
  width: vw(20px);
  height: auto;
  margin-right: vw(10px);
}
.go-back-text {
  //font-size: vw(19px);
  font-size: vw(14px);
  line-height: vw(57px);
  letter-spacing: vw(-1.56316px);
  @include fontMulishBold;
  color: #3d99e9;
}

.go-back-div {
  cursor: pointer;
  width: fit-content;
}
.color-blue {
  color: rgba(84, 84, 212, 1);
}
.color-gray-opacity-5 {
  color: rgba(25, 25, 27, 0.51) !important;
}

/*================== common text ==============*/
.page_title {
  font-size: vw(64px);
  line-height: vw(95px);
  @include fontSemibold;
}

.medium_32_font {
  font-size: vw(32px);
  line-height: vw(48px);
  @include fontMedium;
}
/*==================  font size  =============*/
.font_24 {
  font-size: vw(24px);
  line-height: vw(35px);
}
.min-100vh {
  min-height: 100vh;
}
.bg-white {
  background: white;
}
