.home-font-50-medium {
  //  font-size: vw(50px);
  //  line-height: vw(66px);
  font-size: vw(55px);
  line-height: vw(71px);
  letter-spacing: vw(1px);
  @include fontMedium;
  color: #020202;
}
.home-font-48-medium {
  //  font-size: vw(48px);
  //  line-height: vw(60px);
  font-size: vw(53px);
  line-height: vw(65px);
  letter-spacing: vw(1px);
  @include fontMedium;
  color: #020202;
}
.home-font-20-mulish {
  font-size: vw(20px);
  line-height: vw(30px);
  //font-size: vw(25px);
  //line-height: vw(38px);
  color: #696871;
  @include fontMulishRegular;
}
.home-blue-btn {
  width: vw(211px);
  height: vw(60px);
  //width: vw(222px);
  //height: vw(70px);
  background: #5454d4;
  border-radius: vw(10px);
  font-size: vw(17px);
  line-height: vw(22px);
  //font-size: vw(22px);
  //line-height: vw(27px);
  text-align: center;
  letter-spacing: vw(-0.531474px);
  color: #ffffff;
  @include fontDmsBold;
}
.home-blue-btn--color-green {
  background: rgba(0, 204, 190, 1);
}
.home-blue-btn--color-pink {
  background: rgba(251, 136, 119, 1);
}
.home-fold1-img-div {
  //width: vw(624px);
  width: vw(858px);
  //height: vw(439px);
  margin-left: vw(80px);
}

/*=====================================================================

                      Our service

====================================================================*/
.home-our-service-div {
  background: rgba(236, 243, 250, 1);
  padding-top: vw(90px);
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: vw(179px);
    left: 0;
    height: vw(179px);
    top: 3%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/img/background/bg1.svg");
  }
  &::after {
    position: absolute;
    content: "";
    width: vw(119px);
    right: 1%;
    height: vw(119px);
    bottom: 10%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/img/background/bg2.svg");
  }
}

.home-our-service-title {
  font-size: vw(50px);
  line-height: vw(57px);
  //font-size: vw(55px);
  //line-height: vw(62px);
  letter-spacing: vw(-1.56316px);
  color: #19191b;
  @include fontDmsBold;
}
.our-service-card {
  width: vw(379px);
  //width: vw(536px);
  height: auto;
  min-height: vw(381px);
  background: #ffffff;
  box-shadow: 0 vw(2px) vw(24px) rgba(88, 126, 236, 0.15);
  border-radius: vw(16px);
  margin-left: vw(21px);
  margin-right: vw(21px);
  padding-bottom: vw(50px);
  //margin-bottom: vw(86px);
  margin-bottom: vw(44px);
}
.img-block-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  //height: vw(282px);
  height: vw(220px);
  padding-bottom: vw(30px);
  //padding-bottom: vw(20px);
}
.service-img1 {
  width: vw(140px);
  //width: vw(198px);
  height: auto;
}
.service-img2 {
  width: vw(129px);
  //width: vw(182px);
  height: auto;
}
.service-img3 {
  width: vw(129px);
  //width: vw(182px);
  height: auto;
}
.service-img4 {
  width: vw(166px);
  //height: auto;
}
.service-img5 {
  width: vw(164px);
  //width: vw(234px);
  height: auto;
}
.card-text-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: vw(80px);
}
.our-service-card-name {
  font-size: vw(24px);
  line-height: vw(36px);
  //font-size: vw(29px);
  //line-height: vw(41px);
  text-align: center;
  letter-spacing: vw(-0.750316px);
  color: #19191b;
  @include fontDmsBold;
  width: vw(280px);
}
.learn-more-text {
  padding-top: vw(16px);
  font-size: vw(17px);
  line-height: vw(22px);
  //font-size: vw(22px);
  //line-height: vw(28px);
  letter-spacing: vw(-0.531474px);
  color: #5454d4;
  @include fontDmsBold;
  padding-bottom: vw(2px);
  cursor: pointer;
}
/*==================================================================================

                         About

=================================================================================*/
.font-24-DMsans-bold {
  font-size: vw(24px);
  line-height: vw(57px);
  //font-size: vw(29px);
  //line-height: vw(62px);
  letter-spacing: vw(-1.56316px);
  color: #19191b;
  @include fontDmsBold;
}
.home-about-36-font {
  //  font-size: vw(36px);
  //  line-height: vw(57px);
  font-size: vw(41px);
  line-height: vw(62px);
  letter-spacing: vw(-1.56316px);
  color: #19191b;
  @include fontDmsMedium;
}
.pl-118 {
  padding-left: vw(120px);
}
.pl-307 {
  padding-left: vw(307px);
}
.home-about-logo-img-div {
  //width: vw(118px);
  //margin-bottom: vw(68px);
  //width: vw(900px);
  width: vw(789px);
  height: auto;
}
.home-about-logoipsum-img-div {
  width: vw(124px);
  height: auto;
  margin-bottom: vw(66px);
}
.home-about-logo3-img-div {
  width: vw(100px);
  margin-bottom: vw(37px);
  height: auto;
}
.about-img1-div {
  //  width: vw(416px);
  //width: vw(588px);
  width: vw(435px);
  height: auto;
  margin-right: vw(54px);
}
.about-img2-div {
  //width: vw(649px);
  //width: vw(459px);
  width: vw(459px);
  height: auto;
  margin-left: vw(123px);
}
.about-img3-div {
  //width: vw(810px);
  //width: vw(534px);
  width: vw(534px);
  height: auto;
  margin-right: vw(76px);
}
.about-img4-div {
  //width: vw(916px);
  //width: vw(648px);
  width: vw(648px);
  height: auto;
  margin-left: vw(40px);
}
.color-blue {
  color: #3d99e9;
}
.font-24-DMsans-bold-home-color-gray {
  color: rgba(25, 25, 27, 0.4);
}
/*===============================
     for our services
=================================*/
.our-services-reduce-your-workload {
  padding-top: vw(141px);
}
.our-services-better-retention-div {
  padding-top: vw(80px);
}
.our-services-pre-screened-candidates-div {
  padding-top: vw(130px);
}
