//-- mixin scss --//

//-- Poppins Semibold --//
@mixin fontSemibold {
  font-family: Poppins-SemiBold, sans-serif, "Helvetica";
  font-weight: 600;
}

//-- Poppins Regular --//
@mixin fontRegular {
  font-family: Poppins-Regular, sans-serif, "Helvetica";
  font-weight: 400;
}

//-- Poppins Bold --//
@mixin fontBold {
  font-family: Poppins-Bold, sans-serif, "Helvetica";
  font-weight: 700;
}

//-- Poppind Medium --//
@mixin fontMedium {
  font-family: Poppins-Medium, sans-serif, "Helvetica";
  font-weight: 500;
}

//-- Poppins ExtraBold --//
@mixin fontExtrabold {
  font-family: Poppins-ExtraBold, sans-serif, "Helvetica";
  font-weight: 900;
}

//-- Poppins Black --//
@mixin fontBlack {
  font-family: Poppins-Black, sans-serif, "Helvetica";
}
/*------------------------------------------------------

                  DM Sans

-----------------------------------------------------*/
@mixin fontDmsBold {
  font-family: DMSans-Bold, sans-serif, "Helvetica";
  font-weight: 700;
}
@mixin fontDms {
  font-family: DMSans-Regular, sans-serif, "Helvetica";
}
@mixin fontDmsMedium {
  font-family: DMSans-Medium, sans-serif, "Helvetica";
  font-weight: 500;
}
/*------------------------------------------------------

                 Mulish

-----------------------------------------------------*/
@mixin fontMulishRegular {
  font-family: Mulish-Regular, sans-serif, "Helvetica";
  font-weight: 400;
}
@mixin fontMulishSemiBold {
  font-family: Mulish-SemiBold, sans-serif, "Helvetica";
  font-weight: 600;
}
@mixin fontMulishBold {
  font-family: Mulish-Bold, sans-serif, "Helvetica";
  font-weight: 700;
}
@mixin fontMulishExtraBold {
  font-family: Mulish-ExtraBold, sans-serif, "Helvetica";
  font-weight: 800;
}
