.training-top-div {
  padding: vw(79px) vw(113px) 0 vw(173px);
}
.training-fold1-img-div {
  //width: vw(687px);
  width: vw(500px);
  height: auto;
  //margin-left: vw(20px);
  margin-left: vw(40px);
}
.home-fold1-left-div {
  width: vw(800px);
}
.training-36-blue-font {
  // font-size: vw(42px);
  // line-height: vw(52px);
  font-size: vw(36px);
  line-height: vw(42px);
  @include fontMulishBold;
  color: #67a4f4;
  text-align: center;
}
.training-fold2-img-div {
  //width: vw(750px);
  width: vw(598px);
  height: auto;
}
.home-fold2-right-div {
  width: 50%;
  margin-left: vw(20px);
}
.blue-color {
  color: rgba(84, 84, 212, 1);
}
.training-card-div {
  padding: vw(94px) vw(94px) vw(124px) vw(98px);
}
.training-card {
  //width: vw(500px);
  //height: vw(420px);
  width: vw(378px);
  min-height: vw(332px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: #ffffff;
  box-shadow: 0 vw(2px) vw(24px) rgba(88, 126, 236, 0.15);
  border-radius: vw(16px);
  //margin-left: vw(32px);
  margin-right: vw(32px);
  margin-bottom: vw(44px);
  padding-bottom: vw(60px);
  padding-left: vw(39px);
  padding-right: vw(55px);
}
.training-card-img-div {
  width: 100%;
  height: vw(250px);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: vw(30px);
}
.traning-logo-img1 {
  //width: vw(122px);
  width: vw(89px);
}
.traning-logo-img2 {
  //width: vw(106px);
  width: vw(85px);
}
.traning-logo-img3 {
  //width: vw(122px);
  width: vw(75px);
}
.traning-logo-img4 {
  //width: vw(85px);
  width: vw(62px);
}
.traning-logo-img5 {
  //width: vw(122px);
  width: vw(89px);
}
.traning-logo-img6 {
  //width: vw(122px);
  width: vw(89px);
}
.traning-logo-img7 {
  // width: vw(122px);
  width: vw(89px);
}
.traning-logo-img8 {
  //width: vw(122px);
  width: vw(89px);
}
.traning-logo-img9 {
  //width: vw(122px);
  width: vw(89px);
}

.training-card-text {
  font-size: vw(24px);
  line-height: vw(36px);
  text-align: center;
  letter-spacing: vw(-0.750316px);
  color: #19191b;
  @include fontDmsBold;
}
.training-card-text-div {
  width: vw(300px);
  min-height: vw(75px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: vw(5px);
}
.training-footer-div {
  padding-top: vw(500px);
}
